<template>
  <div class="" v-if='!formLoading'>
    <BaseForm 
      :editObj='formObj' 
      :getBaseData='getBaseData' 
      ref='baseForm'
    />
    <hr/>
    <ResponsiveFieldsForm 
      :defaultItem="defaultItem" 
      :editObj='formObj' 
      :getRestData='getRestData' 
      :formFields="restFields" 
      ref='restForm' 
    />
    <!-- <hr/> -->
    <DaysRepeater :editObj='formObj' :getRepeaterData='getRepeaterData' ref='daysRepeaterForm'/>
    <!-- <ResponsiveFieldsForm 
      :defaultItem="defaultItem" 
      :editObj='formObj' 
      :getRestData='getImgData' 
      :formFields="imgFields" 
      :openModal="openModal"
      ref='imgForm' 
    /> -->
    <!-- <hr/> -->
    <!-- <ImgForm 
      :editObj='formObj' 
      :getImgData='getImgData' 
      ref='coverimgForm'
      title='Cover'
    />
    <ImgForm 
      :editObj='formObj' 
      :getImgData='getImgData' 
      ref='playerimgForm'
      title='Player'
    /> -->
    <div class="border-top w-100 text-right pt-2">
      <b-button 
        variant="primary"
        @click="formSubmit"  
      > 
        {{ 'Submit' }}
      </b-button>

    </div>
  </div>
</template>

<script>
// import ResponsiveForm from './ResponsiveForm.vue'
import ResponsiveFieldsForm from './ResponsiveFieldsForm.vue';
import BaseForm from './BaseForm.vue';
import ProductRestForm from './ProductRestForm.vue';
import ImgForm from './ImgForm.vue';
import DaysRepeater from './DaysRepeater.vue';

import {
  BRow, BCol, BButton
} from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {mapActions,mapGetters} from 'vuex';

export default {
  components: {
    BButton,
    ResponsiveFieldsForm,
    DaysRepeater,
    BaseForm,
    ProductRestForm,
    ImgForm,
    BRow, BCol
  },
  data() {
    return {
      formObj: null,
      formLoading: false,
      formData: {},
      defaultItem: {
        color: '#FFFFFF',
        status: true,
      },
      selectedCover: {},
      selectedPlayer: {},
      selectedTV: {},
    }
  },

  props: ['editObj', 'closeModal', 'openModal', ' confirmDeleteFullItem'],
  mounted() {
    // console.log(this.editObj)
    this.getPresenters({
      limitedBy: 100,
    });
    if(this.$route.params.item_id) {
      this.formLoading = true
      this.getShow(this.$route.params.item_id).then(res => {
        this.formObj = res.data.results;
        this.formObj.announcers_id = this.formObj.announcers_id && this.formObj.announcers_id.length > 0 ? this.formObj.announcers_id.filter(item => item != null) : [];
        if(this.formObj.cover_image_id) this.selectedCover = { id: this.formObj.cover_image_id }
        if(this.formObj.player_image_id) this.selectedPlayer = { id: this.formObj.player_image_id }
        if(this.formObj.tv_image_id) this.selectedTV = { id: this.formObj.tv_image_id }
        if(this.formObj.social_links) {
          this.formObj.facebook = this.formObj.social_links.facebook;
          this.formObj.twitter = this.formObj.social_links.twitter;
          this.formObj.instagram = this.formObj.social_links.instagram;
          this.formObj.youtube = this.formObj.social_links.youtube;
          this.formObj.whatsapp = this.formObj.social_links.whatsapp;
        }

        setTimeout(() => {
          this.formLoading = false;
        }, 100)
        this.$forceUpdate();
      })
      // this.getLabel(this.editObj.id)
      //   .then(res => {
      //     this.formData.id = this.editObj.id;
      //     this.formLoading = false;
      //   })
    }
  },
  methods: {
    ...mapActions(['getShows', 'getPresenters', 'getShow', 'createShow']),
    formSubmit()  {
      this.$refs.baseForm.submit()
        .then(baseFormRes => {
          if(baseFormRes == true) {
            this.$refs.daysRepeaterForm.submit()
              .then(daysRepeaterFormRes => {
                if(daysRepeaterFormRes == true) {
                  this.$refs.restForm.submit()
                  .then(restFormRes => {
                    if(restFormRes == true) {
                      this.$store.commit('setModalLoading', true);
                      this.$refs.restForm.submit();
                      this.$refs.baseForm.submit();
                      this.$refs.daysRepeaterForm.submit();
                      // this.$refs.coverimgForm.submit();
                      // this.$refs.playerimgForm.submit();
                      console.log(this.formData)
                      this.checkNulldesc();
                      setTimeout(() => {
                        this.createShow({
                          ...this.formData,
                          cover_image_id: this.selectedCover.file_id,
                          player_image_id: this.selectPlayer.file_id,
                          tv_image_id: this.selectedTV.file_id,
                        })
                          .then(res => {
                            // this.closeModal();
                            this.$router.go(-1);
                            this.getShows({});
                            this.$store.commit('setModalLoading', false);
                          }).catch(err => {
                            this.$store.commit('setModalLoading', false);
                            this.$toast({
                              component: ToastificationContent,
                              props: {
                                title: this.$t(err.response.data.results.message),
                                icon: 'XIcon',
                                variant: 'danger',
                              },
                            });
                          })
                      }, 100)
                    }
                  })
                }
              })

          }
        })
    },
    
    getBaseData(data) {
      this.formData.titles = data.titles;
      this.formData.descriptions = data.descriptions;
    },
    getImgData(data, key) {
      console.log(data)
      // this.formData.base64 = [];
      // if(this.editObj && this.editObj.image) {
      //   this.formData.base64 = [
      //     {
      //       ...this.editObj.image,
      //       delete: true
      //     }
      //   ]
      // }
      // if(key == 'Cover') {
      //   this.formData.cover_image_base64 = data;
      // }else if(key == 'Player'){
      //   this.formData.player_image_base64 = data;
      // }else {
      //   this.formData.base64 = [
      //     ...this.formData.base64,
      //     data,
      //   ];
      // }
      // console.log(this.formData.base64)
    },
      
    getRestData(data) {
      this.formData = {
        ...this.formData,
        ...data,
      }
    },
    getRepeaterData(data) {
      this.formData.times = data.items;
      this.formData.deleted_times = data.deleted_times;
      console.log(this.formData.times)
    },
    checkNulldesc() {
        if(!this.formData.descriptions.ar || this.formData.descriptions.ar == null || this.formData.descriptions.ar == '') {
          this.formData.descriptions.ar = this.formData.descriptions.en
        }
        if(!this.formData.descriptions.en || this.formData.descriptions.en == null || this.formData.descriptions.en == '') {
          this.formData.descriptions.en = this.formData.descriptions.ar
        }
    },
    searchPresenters(searchText) {
      this.getPresenters({
        keywords: searchText
      })
    },
    selectCover(file) {
      if(this.$route.params.item_id) {
        delete this.formObj.player_image_id;
        delete this.formObj.tv_image_id;
        this.createShow({
          ...this.formObj,
          cover_image_id: file.id
        }).then(res => {
          this.selectedCover = file;
        })
      } else {
        this.selectedCover = file;
      }
    },
    selectPlayer(file) {
      if(this.$route.params.item_id) {
        delete this.formObj.cover_image_id;
        delete this.formObj.tv_image_id;
        this.createShow({
          ...this.formObj,
          player_image_id: file.id
        }).then(res => {
          this.selectedPlayer = file;
        })
      } else {
        this.selectedPlayer = file;
      }
    },
    selectTV(file) {
      if(this.$route.params.item_id) {
        delete this.formObj.cover_image_id;
        delete this.formObj.player_image_id;
        this.createShow({
          ...this.formObj,
          tv_image_id: file.id
        }).then(res => {
          this.selectedTV = file;
        })
      } else {
        this.selectedTV = file;
      }
    },

  },

  computed: {
    ...mapGetters(['presenters', 'modalLoading']),
    restFields() {
      return [
        {name: 'Facebook', type: 'text', rules: '', model: 'facebook', cols: 4},
        {name: 'Twitter', type: 'text', rules: '', model: 'twitter', cols: 4},
        {name: 'Instagram', type: 'text', rules: '', model: 'instagram', cols: 4},
        {name: 'Whatsapp', type: 'text', rules: '', model: 'whatsapp', },
        {name: 'Youtube', type: 'text', rules: '', model: 'youtube', },
        {type: 'divider'},
        {
          name: 'Announcers', type: 'searchSelect', rules: '', model: 'announcers_id', value: 'id',
          multi: true , options: this.presenters, label: 'title', searchItems: this.searchPresenters, cols:12
        },
        // {name: 'status', type: 'checkbox', rules: 'required', model: 'status' },
      ]
    },
    imgFields() {
      return [
        {name: 'Cover Img', type: 'dropzone', selectedOpt: true, selectable: this.selectedCover, model: 'dropzone', cols: 4, selectFile: this.selectCover, multiple: true },
        {name: 'Player Img', type: 'dropzone', selectedOpt: true, selectable: this.selectedPlayer, model: 'dropzone', cols: 4, selectFile: this.selectPlayer, multiple: true },
        {name: 'TV Img', type: 'dropzone', selectedOpt: true, selectable: this.selectedTV, model: 'dropzone', cols: 4, selectFile: this.selectTV, multiple: true },
        // {name: 'status', type: 'checkbox', rules: 'required', model: 'status' },
      ]
    }
  }

}
</script>