<template>

<div>
  <!-- {{ editObj }} -->
  <DropZone
    :selectable="editObj.selectable" 
    :selectedOpt="editObj.selectedOpt" 
    @fileSelected="selectFile" 
    :multiple="editObj.multiple"
    :types="editObj.types"
    :name="editObj.name"
    :field="editObj"
    :limitedHeight="editObj.limitedHeight"
  ></DropZone>
</div>
</template>

<script>
import DropZone from './dropzone/DropZone.vue';

export default {
  components: {
    DropZone
  },
  props: ['editObj', 'closeModal'],

  methods: {
    selectFile(data) {
      this.editObj.selectFile(data.file, this.editObj);
      if(!this.$route.params.item_id) {
        this.closeModal();
      }
    },
    
  }
}

</script>