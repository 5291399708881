<template>

  <div v-if="!isLoading">
    <ResponsiveFieldsForm 
      :defaultItem="{}" 
      :editObj='formObj' 
      :getRestData='getRestData' 
      :formFields="restFields" 
      ref='restForm' 
    />
  </div>

</template>

<script>
import { mapGetters } from 'vuex';
import ResponsiveFieldsForm from './ResponsiveFieldsForm.vue';

export default {
  components: {
    ResponsiveFieldsForm
  },
  data() {
    return {
      isLoading: true,
      formObj: {},
      formData: {},
    }
  },
  props: ['editObj', 'closeModal'],
  mounted() {
    this.formObj = {
      ...this.modalCustomOpts.item,
    };

    //* set title in the custom keys so we can split the fields
    if(this.modalCustomOpts.key == 'description') {
      this.formObj.descriptionAr = this.formObj.descriptions.ar || '';
      this.formObj.descriptionEn = this.formObj.descriptions.en || '';
    } else if(this.modalCustomOpts.key == 'title') {
      this.formObj.titleAr = this.formObj.titles.ar || '';
      this.formObj.titleEn = this.formObj.titles.en || '';
    } else if(this.showsRoute) {
      this.formObj[this.modalCustomOpts.key] = this.formObj.social_links[this.modalCustomOpts.key] || '';
    } else if(this.presentersRoute) {
      this.formObj[this.modalCustomOpts.key] = this.formObj.contact[this.modalCustomOpts.key] || '';

    }
    this.isLoading = false;
  },
  methods: {
    formSubmit() {
      this.$refs.restForm.submit()
        .then(res =>  {
          if(res) {
            this.modalCustomOpts.submitFn(this.formData);
          }
        })
    },
    getRestData(data) {
      //* set title in the original keys so we can save them
      if(this.modalCustomOpts.key == 'description') {
        this.formData = {
          id: this.formObj.id,
          ...this.formObj.social_links,
          ...this.formObj.contact,
          descriptions: {
            ar: data.descriptionAr,
            en: data.descriptionEn,
          },
        }
      } else if(this.modalCustomOpts.key == 'title') {
        this.formData = {
          id: this.formObj.id,
          ...this.formObj.social_links,
          ...this.formObj.contact,
          titles: {
            ar: data.titleAr,
            en: data.titleEn,
          }
        }
      }else if(this.presentersRoute) {
        this.formData = {
          id: this.formObj.id,
          ...this.formObj.contact,
        }
        this.formData[this.modalCustomOpts.key] = data[this.modalCustomOpts.key];
      } else if(this.showsRoute) {
        this.formData = {
          id: this.formObj.id,
          ...this.formObj.social_links,
        }
        this.formData[this.modalCustomOpts.key] = data[this.modalCustomOpts.key];
      }
    }
  },
  computed: {
    ...mapGetters(['modalCustomOpts']),
    restFields() {
      return this.modalCustomOpts.formFields;
    },
    presentersRoute() {
      return this.$route.name == 'Presenters';
    },
    showsRoute() {
      return this.$route.name == 'Shows';
    }
  }
}

</script>