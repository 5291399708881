<template>
  <div class="" v-if='!formLoading && formObj != null'>
    <!-- <BaseForm 
      :editObj='formObj' 
      :getBaseData='getBaseData' 
      ref='baseForm'
    /> -->
    <hr/>
    <ResponsiveFieldsForm 
      :defaultItem="defaultItem" 
      :editObj='formObj' 
      :getRestData='getRestData' 
      :formFields="restFields" 
      ref='restForm' 
      :openModal="openModal"
      v-show="step == 'rest'"
    />
    <ResponsiveFieldsForm 
      :defaultItem="defaultItem" 
      :editObj='formObj' 
      :getRestData='getMainData' 
      :formFields="mainFields" 
      ref='mainForm' 
      :openModal="openModal"
      v-show="step == 'main'"
    />
    <hr/>
    <!-- <DaysRepeater :editObj='formObj' :getRepeaterData='getRepeaterData' ref='daysRepeaterForm'/> -->
    <!-- <b-row class="my-1 px-2" v-if='formObj && formObj.programs'>
      <h5>Programs</h5>
      <b-table striped :fields="programsFields" :items="formObj.programs"></b-table>
    </b-row> -->
    <!-- <hr/> -->
    <!-- <ImgForm 
      :editObj='formObj' 
      :getImgData='getImgData' 
      ref='coverimgForm'
      title='Cover'
    />
    <ImgForm 
      :editObj='formObj' 
      :getImgData='getImgData' 
      ref='playerimgForm'
      title='Player'
    /> -->
    <div class="d-flex justify-content-end">

      <b-button 
        variant="dark"
        @click="showStep('rest')"  
        v-if="step == 'main'"
        class="px-2"
      > 
        {{ 'Next' }}
      </b-button>
      <b-button 
        variant="dark"
        @click="showStep('main')"  
        v-if="step == 'rest'"
        class="px-2"
      > 
        {{ 'Previous' }}
      </b-button>
      <b-button 
        v-if="step == 'rest'"
        variant="primary"
        @click="formSubmit"  
        class="px-2 mx-1"
      > 
        {{ 'Submit' }}
      </b-button>
    </div>
  </div>
</template>

<script>
// import ResponsiveForm from './ResponsiveForm.vue'

import ResponsiveFieldsForm from './ResponsiveFieldsForm.vue';
import BaseForm from './BaseForm.vue';
import ProductRestForm from './ProductRestForm.vue';
import ImgForm from './ImgForm.vue';
import DaysRepeater from './DaysRepeater.vue';

import {
  BRow, BCol, BCard, BCardHeader, BTable, BButton
} from 'bootstrap-vue';

import {mapActions,mapGetters} from 'vuex';

export default {
  components: {
    ResponsiveFieldsForm,
    DaysRepeater,
    BaseForm,
    ProductRestForm,
    ImgForm,
    BRow, BCol, BCard,
    BCardHeader, BTable, BButton
  },
  data() {
    return {
      formObj: null,
      formLoading: false,
      formData: {},
      newPresenter: {},
      step: 'main',
      defaultItem: {
        color: '#FFFFFF',
        status: true,
      },
      programsFields: [
        {
          key: 'id',
          label: this.$t('#')
        },
        {
          key: 'title',
          label: this.$t('title')
        },
        {
          key: 'description',
          label: this.$t('description')
        },
      ],
      selectedCover: {},
      selectedPlayer: {},
      shows: []
    }
  },

  props: ['editObj', 'closeModal', 'openModal'],
  mounted() {
    this.searchShows();
    if(this.$route.params.item_id && false) {
      this.formLoading = true;
      this.getPresenter(this.$route.params.item_id).then(res => {
        this.formObj = res.data.results;
        if(this.formObj.cover_image_id) this.selectedCover = { ...this.formObj.cover_image }
        if(this.formObj.image_id) this.selectedPlayer = { ...this.formObj.image }
        if(this.formObj.contact) {
          this.formObj.facebook = this.formObj.contact.facebook;
          this.formObj.twitter = this.formObj.contact.twitter;
          this.formObj.instagram = this.formObj.contact.instagram;
          this.formObj.youtube = this.formObj.contact.youtube;
          this.formObj.whatsapp = this.formObj.contact.whatsapp;
        }
        setTimeout(() => {
          this.formLoading = false;
        }, 100)
        this.$forceUpdate();
      })
      // this.getLabel(this.editObj.id)
      //   .then(res => {
      //     this.formData.id = this.editObj.id;
      //     this.formLoading = false;
      //   })
    }else {
      this.formLoading = false;
      this.formObj = {}
    }
  },
  methods: {
    ...mapActions(['getShows', 'getPresenters', 'getShow', 'createShow', 'createPresenter', 'getPresenter']),
    formSubmit()  {
      this.$refs.restForm.submit()
      .then(res => {
        if(res == true) {
          this.$store.commit('setModalLoading', true);
          this.$refs.restForm.submit();
          this.$refs.mainForm.submit();
          // this.$refs.baseForm.submit();
          // this.$refs.daysRepeaterForm.submit();
          // this.$refs.coverimgForm.submit();
          // this.$refs.playerimgForm.submit();
          // console.log(this.formData)
          this.checkNulldesc();
          setTimeout(() => {
            this.createPresenter({
              ...this.formData,
              // player_image_id: this.selectedCover.id,
              status: true,
              cover_image_id: this.selectedCover.id,
              image_id: this.selectPlayer.id,
            })
              .then(res => {
                this.newPresenter = res.data.results;
                this.addPresenterToShows(this.formData.shows, this.newPresenter.id)
                console.log("setTimeout => this.newPresenter:", this.newPresenter)
              })
          }, 100)
        }
      })
    },
    resolveCreate(newID) {
      this.$store.commit('setModalLoading', false);
      this.closeModal();
      setTimeout(() => {
        let item = {name: 'Cover Img', type: 'dropzone', selectable: this.selectedCover, model: 'dropzone', cols: 6, selectFile: this.selectCover, multiple: false };
        this.openModal({item}, 'SelectImage', 'Presenter Cover Image');
        this.$router.replace({
          name: this.$route.name,
          params: {
            item_id: newID,
            upload: 'cover',
          }
        })
      }, 100);
      this.getPresenters({
        limitedBy: -1,
        all_locale: 1
      });
    },
    addPresenterToShows(shows, newID) {
      if(!shows || shows.length == 0) {
        return;
        this.resolveCreate();
      } else {
        let addPromises = [];
        shows.forEach(show => {
          let announcers = show.announcers ? show.announcers.map(ann => ann.id) : [];
          let promsie = this.createShow({
            ...show,
            announcers_id: [...announcers, newID]
          })
          addPromises.push(promsie);

          Promise.all(
            addPromises
          ).then(res => {
            this.resolveCreate(newID);
          })
        })
      }
    },
    showStep(step) {
      if (step == 'rest') {
        this.$refs.mainForm.submit()
          .then(valide => {
            if ( valide ) {
              this.step = step;
            }
          })
      } else {
          
        this.step = step;
      }
    },
    getBaseData(data) {
      this.formData.titles = data.titles;
      this.formData.descriptions = data.descriptions;
    },
      
    getRestData(data) {
      this.formData = {
        ...this.formData,
        ...data,
      }
    },
    getMainData(data) {
      this.formData = {
        ...this.formData,
        titles: {
          ar: data.title_ar,
          en: data.title_en,
        },
        descriptions: {
          ar: data.description_ar,
          en: data.description_en,
        },
      }
    },
    getRepeaterData(data) {
      this.formData.times = data;
    },
    checkNulldesc() {
      if(this.formData.descriptions.ar =='' || this.formData.descriptions.ar == null) {
        this.formData.descriptions.ar = this.formData.descriptions.en
      }
      if(this.formData.descriptions.en == null || this.formData.descriptions.en == '') {
        this.formData.descriptions.en = this.formData.descriptions.ar
      }
    },
    searchShows(searchText) {
      this.getShows({
        keywords: searchText,
        noMutate: true
      }).then(res => {
        this.shows = res.data.results.data;
      })
    },
    selectCover(file) {
      this.$store.commit('setModalLoading', true);
      if(this.$route.params.item_id) {
        delete this.newPresenter.image_id;
        this.createPresenter({
          ...this.newPresenter,
          cover_image_id: file.id
        }).then(res => {
          // this.selectedCover = file;
          this.closeModal();
          setTimeout(() => {
            let item = {name: 'Player Img', type: 'dropzone', selectable: this.selectedPlayer, model: 'dropzone', cols: 6, selectFile: this.selectPlayer, multiple: false };
            this.openModal({item}, 'SelectImage', 'Presenter Player Image');
            this.$route.replace({
              name: this.$route.name,
              params: {
                item_id: this.$route.params.item_id,
                image: 'player'
              }
            })
          }, 100);
          this.$store.commit('setModalLoading', false);
        })
      } else {
        this.selectedCover = file;
      }
    },
    selectPlayer(file) {
      this.$store.commit('setModalLoading', true);
      if(this.$route.params.item_id) {
        delete this.newPresenter.cover_image_id;
        this.createPresenter({
          ...this.newPresenter,
          image_id: file.id
        }).then(res => {
          // this.selectedPlayer = file;
          this.closeModal();
          this.getPresenters({
            limitedBy: -1,
            all_locale: 1
          })
          this.$store.commit('setModalLoading', false);
        })
      } else {
        this.selectedPlayer = file;
      }
    }
  },

  computed: {
    ...mapGetters(['presenters', 'modalLoading']),
    mainFields() {
      return [
        
        {name: 'Title Arabic', type: 'text', model: 'title_ar', cols: 12, rules: 'required' },
        {name: 'Title English', type: 'text', model: 'title_en', cols: 12, rules: 'required' },
        {name: 'Description Arabic', type: 'textarea', model: 'description_ar', cols: 12, rules: 'required' },
        {name: 'Description English', type: 'textarea', model: 'description_en', cols: 12, rules: 'required' },
        // {name: 'Cover Img', type: 'dropzone', selectedOpt: true, selectable: this.selectedCover, model: 'dropzone', cols: 6, selectFile: this.selectCover, multiple: false },
        // {name: 'Player Img', type: 'dropzone', selectedOpt: true, selectable: this.selectedPlayer, model: 'dropzone', cols: 6, selectFile: this.selectPlayer, multiple: false },
        
        // {name: 'status', type: 'checkbox', rules: 'required', model: 'status' },
      ]
    },
    restFields() {
      return [
        {name: 'Facebook', type: 'text', rules: '', model: 'facebook', cols: 4},
        {name: 'Twitter', type: 'text', rules: '', model: 'twitter', cols: 4},
        {name: 'Instagram', type: 'text', rules: '', model: 'instagram', cols: 4},
        {name: 'Whatsapp', type: 'text', rules: '', model: 'whatsapp', },
        {name: 'Youtube', type: 'text', rules: '', model: 'youtube', },
        {type: 'divider', cols: 12},
        {
          name: 'Shows', type: 'searchSelectItem', rules: '', model: 'shows', 
          multi: true , options: this.shows, label: 'title', searchItems: this.searchShows, cols: 12
        },
      ]
    }
  }

}
</script>